/* global
 $x
 autosize
 bsCustomFileInput
 checkRedirect
 gettext
 initHtmlTextarea
 removeText
 */

// #############################################################################
// BETTER FOCUS

const $betterFocus = new $x.BetterFocus();
$betterFocus.init();

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();

// #############################################################################
// FORM: MULTIPLE SELECT ITEMS

$x.initMultipleSelectItems = () => {
  const $inputs = document.querySelectorAll("[data-multiple-select-item]");

  $inputs.forEach(function ($input) {
    if ($input.$multipleSelectItem) {
      return;
    }

    $input.$multipleSelectItem = new $x.MultipleSelectItems($input);
    $input.$multipleSelectItem.init();
  });
};

// #############################################################################
// FORM

$x.initFormDefaults = function ($parent = $("body")) {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  // TODO: Selber schreiben!
  autosize($("textarea", $parent));

  // HTML TinyMCE
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  const $ajaxUpload = new $x.AjaxUpload($("[data-ajax-upload]", $parent), {
    onUploadCompleted: function ($upload, $data) {
      $x.replaceHtml($data);
    },
  });

  // Form set
  $("[data-form-set]", $parent).formSet();

  $x.initMultipleSelectItems();

  return {
    ajaxUpload: $ajaxUpload,
  };
};

const $formDefaults = $x.initFormDefaults();

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function () {
    $formDefaults.ajaxUpload.reset();
  },
  afterSubmit: function (request, $form, $data) {
    if ($data.submit === "success") {
      if ($data.redirect) {
        checkRedirect($data);
      } else {
        $x.replaceHtml($data);

        if ($data.toaster) {
          $("body").toaster("updateToaster", $data.toaster);
        }
      }
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();

// Loading

function add_loading_listener () {
  $("[data-show-loading]").off("click");
  $("[data-show-loading]").on("click", function () {
    $(".loader").show();
    $(".loader_text").show();
  });
}

add_loading_listener();

// #############################################################################
// DATA TABLE

const $dataTables = document.querySelectorAll("[data-table]");

$dataTables.forEach(function ($item) {
  $item.$datatable = new $x.DataTables($item, {
    api: function ($table, $api) {
      // API: https://datatables.net/reference/api/

      $api.on("draw", function () {
        $("[data-toggle=tooltip]", $table).tooltip();
      });
    },
    customizeCSV: function (csv) {
      // For customization read https://datatables.net/reference/button/csv

      return csv;
    },
    rowGroupStartRender: function ($table, $rows, html) {
      if ($table.id === "prepared_order_table") {
        $rows.every(function (index, tableLoop, rowLoop) {
          if (rowLoop === 0) {
            const $row = this;
            const data = $row.data();
            const position_no = data.position__position_no;
            const amount = $x.removeText(data.amount);
            const width_in_cm = $x.removeText(data.width_in_cm);
            const height_in_cm = $x.removeText(data.height_in_cm);
            const length_in_m = $x.removeText(data.length_in_m);
            const wood_type = $x.removeText(data.wood_type__name);
            const strength_class = $x.removeText(data.strength_class__name);
            const surface_quality = $x.removeText(data.surface_quality);
            const delivery_date = $x.removeText(data.position__delivery_date);

            html = "<div class=\"row\">";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Position number") + "</strong><br>" + position_no + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0 ml-xl-auto\"><strong class=\"font-ubuntu-regular\">" + gettext("Amount") + "</strong><br>" + amount + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0 \"><strong class=\"font-ubuntu-regular\">" + gettext("Width") + "</strong><br>" + width_in_cm + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Height") + "</strong><br>" + height_in_cm + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Length") + "</strong><br>" + length_in_m + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0 ml-xl-auto\"><strong class=\"font-ubuntu-regular\">" + gettext("Wood type") + "</strong><br>" + wood_type + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Strength class") + "</strong><br>" + strength_class + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Surface quality") + "</strong><br>" + surface_quality + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Profile") + "</strong><br>" + data.profile + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0 ml-xl-auto\"><strong class=\"font-ubuntu-regular\">" + gettext("Delivery date") + "</strong><br>" + delivery_date + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Status") + "</strong><br>" + data.position__status__name + "</div>";
            html += "<div class=\"col-12 col-lg-3 my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Comment") + "</strong><br>" + data.position__comment + "</div>";
            html += "</div>";
          }
        });
      }

      return html;
    },
  });
});

// manage selected rows in hidden field to send to server
$("#production_unit_split_table tbody").on("click", "tr", function () {
  $(this).toggleClass("selected");

  const component_id = $(this).attr("id");
  let split_ids = $("#id_split_components").val();

  if (!split_ids || split_ids === "") {
    split_ids = [];
  } else {
    split_ids = split_ids.split(",");
  }

  if ($(this).hasClass("selected")) {
    split_ids.push(component_id); // add component_id
  } else {
    split_ids = split_ids.filter(item => item !== component_id); // remove component_id
  }

  $("#id_split_components").val(split_ids.join());
});

// #############################################################################
// FULL CALENDAR

const $calendars = document.querySelectorAll("[data-calendar]");

$calendars.forEach(function ($item) {
  $item.$calendar = new $x.FullCalendar($item);
});

// #############################################################################
// CHART JS

const $charts = document.querySelectorAll("[data-chartjs]");

$charts.forEach(function ($element) {
  const $chartJS = new $x.ChartJS($element);
  $chartJS.init();
});

// #############################################################################
// MODAL

$x.onModalOpenDefault = function ($modal) {
  $("[autofocus]", $modal).focus();
  $("[data-toggle=tooltip]", $modal).tooltip();

  const $formDefaults = $x.initFormDefaults($modal);
  $createOrderConfirmation.init();
  $addDemandForm.init();

  // Loading

  add_loading_listener();

  // Validation

  $("[data-form]", $modal).formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
    },
    afterSubmit: function ($xhr, $form, $data) {
      $(".loader").hide();
      $(".loader_text").hide();
      if ($data.submit === "success") {
        $modal.modal("hide");

        if ($data.redirect) {
          checkRedirect($data);
        } else {
          $x.replaceHtml($data);

          add_loading_listener();

          if ($data.toaster) {
            $("body").toaster("updateToaster", $data.toaster);
          }

          $dataTables.forEach(function ($item) {
            $item.$datatable.reload();
          });

          $calendars.forEach(function ($item) {
            $item.$calendar.$api.refetchEvents();
          });
        }
      }
    },
  });

  // Wizard

  $("[data-form-wizard]", $modal).formWizard();
};

$x.delegateEvent.on(document, "click", "[data-modal-link]", function (e) {
  e.preventDefault();

  $x.modal.open(this.href, {
    onModalOpen: $x.onModalOpenDefault,
  });
});


// #############################################################################
// TABS

$("a[data-toggle=\"tab\"]").on("shown.bs.tab", function () {
  const $tab = document.getElementById(this.href.split("#")[1]);

  $tab.querySelectorAll("[data-table]").forEach(function ($item) {
    $item.$datatable.$api.columns.adjust().draw();
  });

  $tab.querySelectorAll("[data-calendar]").forEach(function ($item) {
    $item.$calendar.reload();
  });
});


// #############################################################################
// DOWNLOAD BLOB

$x.DownloadBlob = class {
  constructor ($options) {
    const $defaults = {
      onDownloadStarted: function ($data) {
      },
    };

    this.$settings = { ...$defaults, ...$options };
  }

  download (href) {
    const _this = this;

    $.ajax({
      dataType: "json",
      type: "GET",
      url: href,
      success: function ($data) {
        _this.$settings.onDownloadStarted($data);

        if ($data.base64) {
          const $blob = _this._base64toBlob($data.base64, $data.content_type);
          _this._downloadBlob($blob, $data.file_name);
        }
        $dataTables.forEach(function ($item) {
          $item.$datatable.reload();
        });
      },
    });
  }

  _downloadBlob ($data, file_name) {
    const url = window.URL || window.webkitURL;
    const $a = $("<a>");

    $("body").append($a);

    $a[0].href = url.createObjectURL($data);
    $a[0].download = file_name;
    $a[0].click();

    window.URL.revokeObjectURL(url);
    $a.remove();
  }

  _base64toBlob (data, content_type, slice_size = 512) {
    const $byte_characters = atob(data);
    const $byte = [];

    for (let offset = 0; offset < $byte_characters.length; offset += slice_size) {
      const slice = $byte_characters.slice(offset, offset + slice_size);

      const $byte_numbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        $byte_numbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array($byte_numbers);

      $byte.push(byteArray);
    }

    return new Blob($byte, {
      type: content_type,
    });
  }
};


$x.delegateEvent.on(document, "click", "[data-download]", function (e) {
  e.preventDefault();

  const $downloadBlob = new $x.DownloadBlob({
    onDownloadStarted: function ($data) {
      $("body").toaster("updateToaster", $data.toaster);

      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });
    },
  });

  $downloadBlob.download(this.href);
});


// #############################################################################
// CLIPBOARD

$("body").clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$("body").toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

// TODO: Demo erstellen

$("body").autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});

// #############################################################################
// PREPARE ORDER

$x.delegateEvent.on(document, "click", "[data-prepare-order]", function (e) {
  e.preventDefault();

  $x.ajax.get(this.href, {
    dataType: "json",
    success: ($data) => {
      // $x.replaceHtml($data);
      // $("body").toaster("updateToaster", $data.toaster);
      // hack-patch: necessary, otherwise, the context of PositionsView is not available after replaceHtml
      window.location.reload();
      // console.log($data);
    },
  });
});

// #############################################################################
// STORAGE INVENTORY

function getCookie (name) { // TODO: ask mh, if this already exists -> REMOVE THIS AND MAKE GLOBAL METH
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + "=")) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

class StorageInventory {
  constructor ($options) {
    const $defaults = {
      filter: "[data-storage-inventory-filter]",
      table: "[data-storage-inventory]",
    };

    this._$settings = { ...$defaults, ...$options };
    this._$table = document.querySelector(this._$settings.table);
    this._$date_filter = document.querySelector(this._$settings.filter);
  }

  init () {
    const _this = this;
    if (_this._$date_filter) {
      _this._$date_filter.value = _this._$date_filter.defaultValue;
    }

    if (_this._$table) {
      const href = window.location.pathname;

      $x.delegateEvent.on(document, "input", _this._$settings.filter, function () {
        $x.ajax.post(href, {
          data: { mydate: _this._$date_filter.value },
          beforeSend: function ($xhr) {
            $xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken")); // TODO: replace getCookie and chekc if correct
          },
          success: function ($data) {
            _this._$table.innerHTML = $data;
          },
        });
      });
    }
  }
}

const $storageInventory = new StorageInventory();
$storageInventory.init();

// #############################################################################
// STORAGE COMPARISON

class StorageComparison {
  constructor () {
    const date_from_id = "#storage-comparison-filter-date_from";
    const date_to_id = "#storage-comparison-filter-date_to";
    const filter_class = ".storage-comparison-filter";
    const storageComparison_id = "#storage_comparison_table";

    this._$filter_date_from = document.querySelector(date_from_id);
    this._$filter_date_to = document.querySelector(date_to_id);
    this._$table = document.querySelector(storageComparison_id);

    const _this = this;
    if (_this._$filter_date_from && _this._$filter_date_to) {
      _this._$filter_date_from.value = _this._$filter_date_from.defaultValue;
      _this._$filter_date_to.value = _this._$filter_date_to.defaultValue;
    }

    if (_this._$table) {
      const href = window.location.pathname;

      $x.delegateEvent.on(document, "input", filter_class, function () {
        $x.ajax.post(href, {
          data: { date_from: _this._$filter_date_from.value, date_to: _this._$filter_date_to.value },
          beforeSend: function ($xhr) {
            $xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken")); // TODO: replace getCookie and chekc if correct
          },
          success: function ($data) {
            _this._$table.innerHTML = $data;
          },
        });
      });
    }
  }
}

const $storageComparison = new StorageComparison();

// #############################################################################
// CREATE ORDER CONFIRMATION

class CreateOrderConfirmation {
  init () {
    const idPrefix = "#id_createorderconfirmationform-";
    const chkDifferingDeliveryAddress = document.querySelector(idPrefix.concat("differing_delivery_address"));
    const txtFieldDeliveryAddress = document.querySelector(idPrefix.concat("delivery_address"));
    const dropDeliveryCondition = document.querySelector(idPrefix.concat("delivery_condition"));
    const txtSurchargeTransport = document.querySelector(idPrefix.concat("transport"));
    const freeDeliveryId = document.querySelector(idPrefix.concat("hidden_free_delivery_id"));

    if (chkDifferingDeliveryAddress && txtFieldDeliveryAddress) {
      const txtFieldDeliveryAddressDefaultAddress = txtFieldDeliveryAddress.value;
      chkDifferingDeliveryAddress.onclick = function () {
        txtFieldDeliveryAddress.disabled = !chkDifferingDeliveryAddress.checked;
        txtFieldDeliveryAddress.required = chkDifferingDeliveryAddress.checked;
        if (txtFieldDeliveryAddress.disabled) {
          txtFieldDeliveryAddress.value = txtFieldDeliveryAddressDefaultAddress;
        }
      };
      chkDifferingDeliveryAddress.onclick();
    }

    if (chkDifferingDeliveryAddress && txtFieldDeliveryAddress && dropDeliveryCondition && txtSurchargeTransport && freeDeliveryId) {
      dropDeliveryCondition.onclick = function () {
        const is_free_delivery = dropDeliveryCondition.value === freeDeliveryId.value;
        txtSurchargeTransport.parentElement.parentElement.parentElement.hidden = !is_free_delivery;
        chkDifferingDeliveryAddress.parentElement.parentElement.hidden = !is_free_delivery;
        txtFieldDeliveryAddress.parentElement.parentElement.hidden = !is_free_delivery;
      };
      dropDeliveryCondition.onclick();
    }
  }
}

const $createOrderConfirmation = new CreateOrderConfirmation();


// #############################################################################
// ADD DEMAND FORM

// for AddDemandForm and all subclass of it. see the IDs at the end of init()
class AddDemandForm {
  init () {
    const _init = function (idPrefix) {
      const dropDemandingStorage = document.querySelector(idPrefix.concat("demanding_storage"));
      const dropRetrievalType = document.querySelector(idPrefix.concat("retrieval_type"));
      const dropDryingChamber = document.querySelector(idPrefix.concat("drying_chamber"));

      const wetDemandingStorageId = document.querySelector(idPrefix.concat("hidden_wet_demanding_storage_id"));
      const dryRetrievalTypeId = document.querySelector(idPrefix.concat("hidden_dry_retrieval_type_id"));

      if (dropDemandingStorage && dropRetrievalType && dropDryingChamber && wetDemandingStorageId && dryRetrievalTypeId) {
        dropDemandingStorage.onclick = function () {
          const retrievalTypeIsEnabled = !(dropDemandingStorage.value === wetDemandingStorageId.value);
          const dryChamberIsEnabled = !(dropDemandingStorage.value === wetDemandingStorageId.value || dropRetrievalType.value === dryRetrievalTypeId.value);
          dropDryingChamber.required = dryChamberIsEnabled;
          dropDryingChamber.parentElement.parentElement.hidden = !dryChamberIsEnabled;
          dropRetrievalType.required = retrievalTypeIsEnabled;
          dropRetrievalType.parentElement.parentElement.hidden = !retrievalTypeIsEnabled;
        };
        dropRetrievalType.onclick = dropDemandingStorage.onclick;
        dropRetrievalType.onclick();
      }
    };
    _init("#id_adddemandform-");
    _init("#id_adddemandpredefinedorderform-");
    _init("#id_adddemandproductionform-");
  }
}

const $addDemandForm = new AddDemandForm();

// #############################################################################
// DELIVERY SELECTION
$x.delegateEvent.on(document, "click", "[data-toaster-action]", function (e) {
  e.preventDefault();
  const _href = this.href;
  $x.ajax.post(_href, {
    data: { js_validation_call: "Any" },
    dataType: "json",
    beforeSend: function ($xhr) {
      $xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
    },
    success: function ($data) {
      if ($data.is_valid === "true") {
        $x.modal.open(_href, {
          onModalOpen: $x.onModalOpenDefault,
        });
      } else {
        $("body").toaster("updateToaster", $data.toaster);
      }
    },
  });
});

// #############################################################################
// DASHBOARD

// get errors to be shown on the dashboard
function dashboard_error_check () {
  $x.ajax.get(document.querySelector("[data-dashboard-errors]").getAttribute("href"), {
    success: ($data) => {
      if ($data) {
        document.getElementById("id_no_dashboard_error").hidden = true;
        document.querySelector("[data-dashboard-errors]").parentElement.hidden = false;
      } else {
        document.getElementById("id_no_dashboard_error").hidden = false;
        document.querySelector("[data-dashboard-errors]").parentElement.hidden = true;
      }
      document.querySelector("[data-dashboard-errors]").innerHTML = $data;
      const btn = document.getElementById("id_check_errors");
      btn.classList.remove("btn-loader");
      btn.querySelector("[data-spinner]").hidden = true;
    },
  });
}

if (document.querySelector("[data-dashboard-errors]")) {
  dashboard_error_check();
}

$x.delegateEvent.on(document, "click", "#id_check_errors", function (e) {
  const btn = document.getElementById("id_check_errors");
  btn.classList.add("btn-loader");
  btn.querySelector("[data-spinner]").hidden = false;
  document.getElementById("id_no_dashboard_error").hidden = true;
  dashboard_error_check();
});

// data-dashboard-errors
// dashboard_errors


// #############################################################################
// SUBMIT CHECKBOX VALUES

// when any checkbox with attribute "data-vincent-submit-checkbox" is clicked,
// all data-vincent-checkbox-item are concat as value string (e.g. "1,2,3,6,7")
// and appended to the href given as value of data-vincent-submit-checkbox (e.g. http://thehrefvalue.at/foo/?pk=1,2,3,6,7)
// and subsequently the new href is triggered
class SubmitCheckboxValues {
  init () {
    const actionButtons = document.querySelectorAll("[data-vincent-submit-checkbox]");

    if (!actionButtons) {
      return;
    }

    const clickFunction = function (href) {
      const selected = [];
      const checkboxItems = document.querySelectorAll("[data-vincent-checkbox-item]"); // must be queried here, since datatable might still load the data
      checkboxItems.forEach(function ($input) {
        if ($input.checked) {
          selected.push($input.value);
        }
      });

      if (selected.length > 0) {
        href += "?pk=" + selected.join(",");
      }

      window.location.href = href;
    };

    actionButtons.forEach(function (actionButton) {
      actionButton.onclick = function () {
        clickFunction(actionButton.getAttribute("data-vincent-submit-checkbox"));
      };
    });
  }
}
const $submitCheckboxValue = new SubmitCheckboxValues();
$submitCheckboxValue.init();


// #############################################################################
// TIMER FOR HTML LINKS
// <a> link requires attributes: data-vincent-timer-href (this link will be set to href attribute after countdown), data-vincent-timer-text , data-vincent-timer-time

class HtmlLinkTimer {
  init () {
    const ONE_SECOND = 1000;
    const links = document.querySelectorAll("[data-vincent-timer-href]"); // must be queried here, since datatable might still load the data

    links.forEach(function (link) {
      link.href = "#"; // disable the link
      let count = parseInt(link.getAttribute("data-vincent-timer-time"));
      const timerFunction = function() {
        if (count === 0) {
          clearInterval(timerId);
          link.textContent = link.getAttribute("data-vincent-timer-text");
          link.href = link.getAttribute("data-vincent-timer-href");
        } else {
          link.textContent = link.getAttribute("data-vincent-timer-text") + " (" + count.toString() + ")";
        }
        count--;
      };

      timerFunction();
      const timerId = setInterval(timerFunction, ONE_SECOND);
    });
  }
}
const $htmlLinkTimer = new HtmlLinkTimer();
$htmlLinkTimer.init();
